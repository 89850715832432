import Highcharts from 'highcharts';
import Map from 'highcharts/modules/map';
Map(Highcharts);
import Data from 'highcharts/modules/data';
Data(Highcharts);
import BoostCanvas from 'highcharts/modules/boost-canvas';
BoostCanvas(Highcharts);
import Boost from 'highcharts/modules/boost';
Boost(Highcharts);

window.Highcharts = Highcharts;
